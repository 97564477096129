import React from 'react'
import subs_icon from '../../assets/subs_icon.png'
import { useAppSelector } from '../../app/hooks'
import { useTranslation } from 'react-i18next';
const BasketPopupItem = () => {
  
  const { t, i18n } = useTranslation();
  
  const  selectedItem = useAppSelector((state) => {
    if(state.priceState?.selected_sub_type !== undefined ){
      console.log("Selected price")
      console.log(state.priceState.selected_sub_type)
      return state.priceState?.selected_sub_type
  }})

  return (
    <>
    { selectedItem ? 
    <div className='flex flex-row w-full min-w-[20vw] h-24 bg-transparent justify-center rounded-md mb-2 bg-[#009999] hover:scale-102 hover:cursor-pointer shadow-md transition '>
      <div className='flex flex-col w-auto justify-center items-center px-6 py-3'>
        {/* <img src={subs_icon} className='w-12 h-12 mr-4 justify-self-start' alt='Date Icon'/> */}
        <span className='font-semibold text-lg text-white border-b-2 border-b-slate-200 mb-2'>{selectedItem.name+ " " + t("pages.payment.form1.text3")}</span>
        <div className='flex flex-row justify-between min-w-full'>
          <span className='font-semibold text-xs text-white'></span>
          <span className='font-semibold text-xs text-white'>{selectedItem.currencyCode ==='USD' ? `$${selectedItem.price}` : selectedItem.price+'₺' }</span>
        </div>
        {/* <span className='font-normal text-xs text-slate-800'> 1 Adet</span> */}
      </div>
    </div>
    : 
    <p className='mb-3'>{t("components.navbar.popover1.text1")}</p>
    }
    </>
  )
}

export default BasketPopupItem