import React, { useState } from 'react'
import { Button } from '@mantine/core'
import BasketItem from './BasketItem'
import { useAppSelector } from '../../app/hooks'
import { useTranslation } from 'react-i18next'

const BasketContainer = () => {
  const [userFormInfosValid, setUserFormInfosValid] = useState(false)
  const { t, i18n } = useTranslation();

  const  selectedItem = useAppSelector((state) => {
    if(state.priceState?.selected_sub_type !== undefined ){
      // console.log("Selected price")
      // console.log(state.priceState.selected_sub_type)
      return state.priceState?.selected_sub_type
  }})

  return (
    selectedItem ? 
    <div className='hidden sm:flex flex-col relative items-center bg-white w-[30vw] h-fit mt-8 rounded-xl pt-6 pb-2 px-6'>
      <div className='flex flex-row w-full items-center mb-2'>
        <p className='font-semibold text-start text-zinc-800 text-lg'>{t("pages.payment.card.text1")}</p>
      </div>
      <div className='flex flex-col relative items-center w-full py-2 h-auto  rounded-lg'>

        {/* list of bought product section */}
        <BasketItem product_name={selectedItem.paymentInterval === 'Monthly' ? t("pages.payment.form1.text1") : t("pages.payment.form1.text2")} price={selectedItem.currencyCode ==='USD' ? `$${selectedItem.price}` : selectedItem.price+'₺' }/>
        
        {/* Total price section */}
        <hr className='solid border-1 rounded-full w-full border-slate-400 mb-2 '/>
        <div className='flex flex-col items-center  bottom-0 w-full'>
        {/* border-t-[1px] border-black */}
          <div className='flex flex-row items-center relative  w-full justify-between  px-5'>
            <span className='text-zinc-800 text-base font-semibold'>
            {t("pages.payment.card.text2")}
            </span>
            <span className='text-zinc-800 text-base font-semibold'>
            {selectedItem.currencyCode ==='USD' ? `$${selectedItem.price}` : selectedItem.price+'₺' }
            </span>
          </div>
        </div>
      </div>
    </div>
    :
    <></>
  )
}

export default BasketContainer