import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INewMeetingResponse } from '../services/types';

const initialState: INewMeetingResponse = {
    status: null,
    data: null,
    id: null
  };

export const meetingSlice = createSlice({
  initialState,
  name: 'meetingSlice',
  reducers: {
    setMeetingInfo: (state, action: PayloadAction<INewMeetingResponse>) => {
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.status = action.payload.status;
    },
    deleteMeetingInfo: (state) => {
        state.data = null;
        state.id = null;
        state.status = null;
    },
  },
});

export default meetingSlice.reducer;

export const { setMeetingInfo, deleteMeetingInfo } = meetingSlice.actions;