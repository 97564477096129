/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import CeremeetLogo from '../../../assets/ceremeetbeyaz.png'
import CeremeetLogo from '../../../assets/cere_logo_1.png'
import IyzicoLogo from '../../../assets/payment_logos.webp'
import { useTranslation } from 'react-i18next';
const NewFooter = () => {
  const { t, i18n } = useTranslation();
  return (
  <footer className="w-full text-gray-600 body-font z-50 mt-[6rem] lg:px-24">
    <div className="container px-5 mx-auto flex items-center sm:flex-row flex-row justify-between">
      <a className="flex title-font font-medium items-center md:justify-start justify-center text-white">
        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg> */}
        {/* <img alt='CeremeetLogo' className='w-14 h-14' src={CeremeetLogo} /> */}
        <span className="ml-1 text-xl">Cere</span>
      </a>
      <p className="hidden sm:block text-sm text-gray-100 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4 mr-6">© 2023 Cerebrum Technologies
        {/* <a href="https://twitter.com/knyttneve" className="text-gray-200 ml-1" rel="noopener noreferrer" target="_blank">@knyttneve</a> */}
      </p>
      <a className='hidden sm:block' href='https://pdate.ceremeet.com/ceremeetPictures/pdf/36929-Cerebrum_Kisisel_Veri_Aktarim_Taahhutnamesi_Aralik22.pdf'>
        <p className='text-sm text-gray-100 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4 mr-6' >{t("components.footer.text1")}</p>
      </a>
      <div className='hidden sm:flex flex-row w-auto justify-center items-center mr-8 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2'>
        {/* <span className='text-white mr-4'>Payment partner</span> */}
        <img alt='IyzicoLogo' className='w-[20rem] h-auto ' src={IyzicoLogo} />
      </div>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start items-center">
        <a className="ml-3 text-gray-100" href='https://twitter.com/Ceretheai'>
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-100" href='https://www.instagram.com/ceretheai'>
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-100" href='https://www.linkedin.com/in/cere-the-ai-/'>
          <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
        <a className="ml-3 text-gray-100" href='https://www.tiktok.com/@ceretheai'>
          <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"  className="w-5 h-5" viewBox="0 0 448 512">
            <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
          </svg>
        </a>
      </span>
    </div>
  </footer>
  )
}

export default NewFooter