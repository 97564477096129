import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ICompanyInfo, IUserData, IUserInfo, IUsersDataContainer } from '../services/types';


// export interface IUserData2 {
//   user: IUserInfo | null  ,
//   company: ICompanyInfo | null,
// }

const initialState: any = {
  data: {} ,
  status: {},
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => initialState,
    setProfileData: (state, action: PayloadAction<IUsersDataContainer>) => {
      state.data = action.payload?.data ;
      state.status = action.payload?.status;
    },
  },
});

export default userSlice.reducer;

export const { logout, setProfileData } = userSlice.actions;
