import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IAddressCreationRequest, IAddressCreationResponseContainer, IContactMessage } from './types';
import { RootState } from '../app/store';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/contact/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authState.access_token
      if( token ){
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  
  endpoints: (builder) => ({
    sendContactMessage : builder.mutation<any,IContactMessage>({
      query(data) {
        return {
          url: '',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      }
    })

  }),
});

export const  {
  useSendContactMessageMutation
} = contactApi