import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILangState {
  lang: string;
}

const initialState: ILangState = {
  lang: 'en-US',
};

export const userSpecSlice = createSlice({
  initialState,
  name: 'userSpecSlice',
  reducers: {
    setUserLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    setDefaultLang:(state ) => {
      state.lang = 'en-US'
    }
  },
});

export default userSpecSlice.reducer;

export const { setUserLang, setDefaultLang } = userSpecSlice.actions;