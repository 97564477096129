import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {  INewMeetingResponse, INewMeetingRequest, IMeetingData } from './types';
import { RootState } from '../app/store';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const meetingsApi = createApi({
  reducerPath: 'meetingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/meetings/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authState.access_token
      if( token ){
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  
  endpoints: (builder) => ({
    createNewMeeting : builder.mutation<INewMeetingResponse,INewMeetingRequest>({
      query(data) {
        return {
          url: '',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      }
    }),
    getMeetingInfo : builder.mutation<any,any>({
      query(meetingId) {
        return {
          url: `${meetingId}`,
          method: 'GET',
          credentials: 'include',
        };
      }
    }),
    updateMeetingInfo : builder.mutation<IMeetingData,INewMeetingRequest>({
      query(data) {
        return {
          url: `${data.id}`,
          method: 'PATCH',
          credentials: 'include',
          body: data
        };
      }
    })
  }),
});

export const  {
  useCreateNewMeetingMutation
} = meetingsApi

