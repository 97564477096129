import React from 'react'

const NotFoundPage = () => {
  return (
    <div className={'flex flex-col items-center min-w-screen w-screen overflow-x-hidden sm:scroll-smooth  sm:snap-always relative min-h-screen  lg:px-24 sm:snap-mandatory sm:snap-y' }>
      <section className="text-gray-600 body-font z-50 h-screen min-h-screen snap-center relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20 sm:mt-12 -mt-4">
          <h1 className="sm:text-6xl text-4xl font-extrabold  title-font text-white mb-8 ">Page not found!</h1>
          
        </div>
        
      </div>
    </section>
    </div>
  )
}

export default NotFoundPage