/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import NewContactForm from '../Forms/NewContactForm'
import { useTranslation } from 'react-i18next';

const NewContactSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="text-white body-font relative z-50 w-full min-h-screen sm:snap-center" id='contact'>
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-6xl text-[42px] font-extrabold title-font mb-6 text-[#009999]">{t("pages.home.contact.header.title")}</h1>
          <p className="lg:w-3/4 mx-auto leading-relaxed text-base text-white">{t("pages.home.contact.header.description")}</p>
        </div>
        <NewContactForm/>
      </div>
    </section>
  )
}

export default NewContactSection