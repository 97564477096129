import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

// before it was slice for modals i changed it to loadingoverlays state handler 
interface IModal {
  isLoadingVisible: boolean,
  isUserInfoPopoverOpen?: boolean, 
  isCartPopoverOpen?: boolean,
  isMembershipModalOpen: boolean,
  isLoginModalOpen: boolean,
  isReturnPolicyModalOpened: boolean,
}
const initialState : IModal = {
  isLoadingVisible: false,
  isUserInfoPopoverOpen: false, 
  isCartPopoverOpen: false, 
  isMembershipModalOpen: false,
  isLoginModalOpen: false,
  isReturnPolicyModalOpened: false,
}


export const modalSlice = createSlice({
  initialState,
  name: 'modalSlice',
  reducers: {
    loaderIsOn: (state) => {
      state.isLoadingVisible = true;
    },
    loaderIsOff: (state) => {
      state.isLoadingVisible = false;
    },
    loginModalIsOn: (state) => {
      state.isLoginModalOpen = true;
    },
    loginModalIsOff: (state) => {
      state.isLoginModalOpen = false;
    },
    userInfoPopoverIsOn: (state) => {
      state.isUserInfoPopoverOpen = true;
    },
    userInfoPopoverIsOff: (state) => {
      state.isUserInfoPopoverOpen = false;
    },
    cartPopoverOpenIsOn: (state) => {
      state.isCartPopoverOpen = true;
    },
    cartPopoverOpenIsOff: (state) => {
      state.isCartPopoverOpen = false;
    },
    membershipModalIsOn: (state) => {
      state.isMembershipModalOpen = true;
    },
    membershipModalIsOff: (state) => {
      state.isMembershipModalOpen = false;
    },
    setModalStatus: (state) => {
      state.isLoadingVisible = false;
    },
    resetModals: (state) => {
      state.isLoadingVisible = false;
    },
    openReturnPolicyPopup: (state) => {
      state.isReturnPolicyModalOpened = true;
    },
    closeReturnPolicyPopup: (state) => {
      state.isReturnPolicyModalOpened = false;
    },
  },
});

export const getToken = (state:RootState) => state.authState.access_token

export default modalSlice.reducer;

export const { openReturnPolicyPopup, closeReturnPolicyPopup, loaderIsOn, loaderIsOff, loginModalIsOff, loginModalIsOn ,membershipModalIsOn, membershipModalIsOff, userInfoPopoverIsOn, userInfoPopoverIsOff, cartPopoverOpenIsOn, cartPopoverOpenIsOff,setModalStatus,  resetModals } = modalSlice.actions;