import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IPricesDataContainer} from './types';
import { RootState } from '../app/store';
import { setPriceData } from '../slices/pricesSlice';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const pricesApi = createApi({
  reducerPath: 'pricesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/prices`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authState.access_token
      if( token ){
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  
  endpoints: (builder) => ({
    getPricesData : builder.mutation<any,void>({
      query(data) {
        return {
          url: '',
          method: 'GET',
          credentials: 'include',
        };
      },
      transformResponse: (result: IPricesDataContainer) =>
        result,
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const data = await queryFulfilled.then( result => result.data );
            dispatch(setPriceData(data));
            console.log("Prices")
            console.log(data)
          } catch (error) {
            console.log("Error occured when trying to get price info!")
          }
        },
    }),
  }),
});

export const  {
  useGetPricesDataMutation
} = pricesApi

