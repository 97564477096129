import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IAddressCreationRequest, IAddressCreationResponseContainer, IAddress } from './types';
import { RootState } from '../app/store';
import { setAllAddresses } from '../slices/addressSlice';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const addressApi = createApi({
  reducerPath: 'addressApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/address/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authState.access_token
      if( token ){
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Addresses','IAddress'],
  endpoints: (builder) => ({
    createNewAddress : builder.mutation<IAddressCreationResponseContainer,IAddressCreationRequest>({
      query({id, ...data}) {
        return {
          url: '',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Addresses']
    }),
    getAddressInfo : builder.query<any,string>({
      query(addressId) {
        return {
          url: `${addressId}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getAllAddressInfo : builder.query<any,string>({
      query(addressId) {
        return {
          url: ``,
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Addresses']
    }),
    updateAddressInfo : builder.mutation<any,IAddressCreationRequest>({
      query( {id, ...data}) {
        return {
          url: `${id}`,
          method: 'PATCH',
          credentials: 'include',
          body: data
        };
      },
      invalidatesTags: ['Addresses']
    }),
    deleteAddressInfo : builder.mutation<any,string>({
      query( addressId) {
        return {
          url: `${addressId}`,
          method: 'PATCH',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Addresses']
    })
  }),
});

export const  {
  useCreateNewAddressMutation,
  useGetAddressInfoQuery,
  useGetAllAddressInfoQuery,
  useDeleteAddressInfoMutation,
  useUpdateAddressInfoMutation
} = addressApi