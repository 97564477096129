/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import FeatureItem from '../Cards/FeatureItem';
import { TypeAnimation } from 'react-type-animation';

const NewFeaturesSection = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <section className="text-gray-600 body-font z-50 min-h-screen h-full sm:snap-center ">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-6xl text-[42px] font-extrabold  title-font text-[#009999] mb-4 ">{t("pages.home.features.header.title")}</h1>
          <p className="text-xl leading-relaxed xl:w-3/4 lg:w-4/5 mx-auto px-4 sm:px-0 text-gray-100 mb-5">{t("pages.home.features.header.description")}</p>
          <div className='flex flex-col w-[60%] justify-between items-center mx-auto bg-gray-900 bg-opacity-90'>
            {/* <p className='justify-self-start text-xl leading-relaxed xl:w-2/4 lg:w-3/4 px-4 sm:px-0 text-gray-100 mb-1'>Learn and Explore or Ask Her</p> */}
            <TypeAnimation
              sequence={[
                t("pages.home.features.questions.q1"), 1000, 
                t("pages.home.features.questions.q2"), 1000, 
                t("pages.home.features.questions.q3"), 1000,
                t("pages.home.features.questions.q4"), 1000,
                t("pages.home.features.questions.q5"), 1000,
                t("pages.home.features.questions.q6"), 1000,
              ]}
              speed={10}
              className='text-gray-100 font-medium text-lg my-2 ' 
              wrapper="span"
              repeat={Infinity}
            />
          </div>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-[#009999] inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-col lg:grid lg:grid-rows-3 lg:grid-cols-3 gap-3  w-full lg:w-[880px] xl:w-[1080px] sm:mx-auto sm:mb-2 relative place-content-center">
          <FeatureItem title={t("pages.home.features.feature1.title")} description={t("pages.home.features.feature1.description")}/>
          <FeatureItem title={t("pages.home.features.feature2.title")} description={t("pages.home.features.feature2.description")}/>
          <FeatureItem title={t("pages.home.features.feature3.title")} description={t("pages.home.features.feature3.description")}/>
          <FeatureItem title={t("pages.home.features.feature4.title")} description={t("pages.home.features.feature4.description")}/>
          <FeatureItem title={t("pages.home.features.feature5.title")} description={t("pages.home.features.feature5.description")}/>
          <FeatureItem title={t("pages.home.features.feature6.title")} description={t("pages.home.features.feature6.description")}/>
        </div>
      </div>
    </section>
  )
}

export default NewFeaturesSection