import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer from '../slices/userSlice';
import { authApi } from '../services/authApi';
import { userApi } from '../services/userApi';
import { uploadAPI } from '../services/uploadAPI';
import { addressApi } from '../services/addressApi';
import { paymentApi } from '../services/paymentApi';
import { meetingsApi } from '../services/meetingsApi';
import authSlice from '../slices/authSlice';
import meetingSlice from '../slices/meetingSlice';
import modalSlice from '../slices/modalSlice';
import userSpecSlice from '../slices/userSpecSlice'

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import pricesSlice from '../slices/pricesSlice';
import { pricesApi } from '../services/pricesApi';
import addressSlice from '../slices/addressSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authState', 'userState', 'meetingState']
};

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [meetingsApi.reducerPath]: meetingsApi.reducer,
  [uploadAPI.reducerPath]: uploadAPI.reducer,
  [addressApi.reducerPath]: addressApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [pricesApi.reducerPath]: pricesApi.reducer,
  userState: userReducer,
  authState: authSlice,
  priceState: pricesSlice,
  meetingState: meetingSlice ,
  modalState: modalSlice,
  userSpecState: userSpecSlice,
  addressState: addressSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([authApi.middleware, userApi.middleware, uploadAPI.middleware, meetingsApi.middleware, paymentApi.middleware, addressApi.middleware,pricesApi.middleware]),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


