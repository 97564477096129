import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../app/store';
import { setProfileData } from '../slices/userSlice';
import { IUsersDataContainer, IUserInfoUpdateResponseContainer, IUserInfoUpdateRequest, IUserData } from './types';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/users/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authState.access_token
      if( token ){
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserInfo: builder.query<IUsersDataContainer, void>({
      query() {
        return {
          url: 'me',
          method: 'GET',
          credentials: 'include',
        };
      },
      transformResponse: (result: IUsersDataContainer) =>
        result,
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const data = await queryFulfilled.then( result => result.data );
            dispatch(setProfileData(data));
            console.log("On the transform response " + data)
          } catch (error) {
            console.log("Error occured when trying to get profile info!")
          }
        },
    }),
    updateUserInfo: builder.mutation<IUserInfoUpdateResponseContainer,IUserInfoUpdateRequest>({
      query( {id, ...data}) {
        return {
          url: `me`,
          method: 'PATCH',
          credentials: 'include',
          body: data
        };
      }
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
} = userApi;

