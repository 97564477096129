import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAddress, INewMeetingResponse, IPriceItem, IPricesDataContainer, IPricesGeneralState } from '../services/types';
import { string } from 'yup/lib/locale';

const initialState: any = {
    selected_address: null,
    all_adresses: null
  };

export const addressSlice = createSlice({
  initialState,
  name: 'addressSlice',
  reducers: {
    setSelectedAddress: (state,action: PayloadAction<string | null| undefined>) => {
        state.selected_address = action.payload;
    },
    setAllAddresses: (state,action: PayloadAction<string | null>) => {
        state.selected_address = action.payload;
    },
    deleteSelectedAddress: (state) => {
        state.selected_address = null;
    },
  },
});

export default addressSlice.reducer;

export const { setSelectedAddress,deleteSelectedAddress,setAllAddresses } = addressSlice.actions;