import React,{useEffect, useState} from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
// import { setPasswordRecoveryEmail } from '../slices/userSlice';
// import LoadingCard from './LoadingCard';
import { useSearchParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import *  as Yup from 'yup'
import { useVerifyEmailMutation } from '../services/authApi';
import RedirectCard from './RedirectCard';

//http://localhost:3000/api/auth/verifyemail/7731dba516f4db1ae4dc49f81196d6235d9ae9445167d8e008a865a561ba26b8 

const VerifyEmail = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch()

  const [sendEmailVerificationCode, response] = useVerifyEmailMutation()

  const [loading, setLoading] = useState(false)
  // let [verificationCode, setVerificationCode] = useSearchParams();

  let {verificationCode} = useParams()

  console.log(verificationCode)


  function redirectToLogin() {
    navigate("/login",{ replace: true })
  }

  useEffect(() => {
    handleVerification()
  }, [loading])


  const handleVerification = async() => {
    try {
      console.log("its on try catch of form1")
      const payload = await sendEmailVerificationCode({verificationCode: verificationCode}).unwrap();
      console.error('fulfilled ', payload);
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        redirectToLogin()
      }, 3000);
    } catch (error) {
      
    }
  }

  return (
    <RedirectCard/>
  )
}

export default VerifyEmail