/* eslint-disable jsx-a11y/anchor-has-content */
import {
    createStyles,
    Header,
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Anchor,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    Avatar,
    Popover,
    Indicator,
    Modal,
    Menu, 
    useMantineTheme 
  } from '@mantine/core';
  import { MantineLogo } from '@mantine/ds';
  import { useDisclosure } from '@mantine/hooks';
  import {
    IconNotification,
    IconCode,
    IconBook,
    IconChartPie3,
    IconFingerprint,
    IconCoin,
    IconChevronDown,
    IconSquareCheck,
    IconPackage,
    IconUsers,
    IconWorld,
    IconLanguage,
  } from '@tabler/icons';

import { useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { UserInfoAction } from './Cards/UserInfoActionCard';
import BasketPopupItem from './BasketPopupItem';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { userInfoPopoverIsOn,userInfoPopoverIsOff, cartPopoverOpenIsOn,cartPopoverOpenIsOff, membershipModalIsOff, loginModalIsOff } from '../../slices/modalSlice';
import { useCancelSubscriptionMutation } from '../../services/paymentApi';
import {loaderIsOn, loaderIsOff} from '../../slices/modalSlice'
import { showNotification } from '@mantine/notifications';
import CeremeetLogo from '../../assets/ceremeetbeyaz.png'
import CereLogo from '../../assets/cerelogo_.svg'
import { useTranslation } from 'react-i18next';

  const useStyles = createStyles((theme) => ({
    container:{
      display:'flex',
      // marginBottom:'2rem',
      height:'auto',
      flexDirection:'row',
      justifyContent:'center',
      width:'100%',
      backgroundColor:'transparent',
      border:'none',
      top: 0,
      position: 'relative',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      // height: '100%',
      marginLeft:4,
      marginRight:4,
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      color: 'white',
      fontWeight: 600,
      fontSize: theme.fontSizes.md,
      backgroundColor: 'transparent',
  
      [theme.fn.smallerThan('sm')]: {
        height: 42,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        color: 'black'
      },
  
      ...theme.fn.hover({
        backgroundColor: 'white',
        color:'#2f0043'
      }),
    },
    link2: {
      display: 'flex',
      alignItems: 'center',
      // height: '100%',
      marginLeft:4,
      marginRight:4,
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      color: 'white',
      fontWeight: 600,
      fontSize: theme.fontSizes.md,
      backgroundColor: '#009999',
  
      [theme.fn.smallerThan('sm')]: {
        height: 42,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        color: 'black'
      },
  
      ...theme.fn.hover({
        backgroundColor: 'white',
        color:'#2f0043'
      }),
    },

    logo:{
      fontSize:'34px',
      color:'white',
      fontWeight: 'bold',
      backgroundColor:'transparent',
      textAlign: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.fn.smallerThan('sm')]: {
        fontSize: '26px',
      },
    },
  
    subLink: {
      width: '10vw',
      padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
      borderRadius: theme.radius.md,
  
      ...theme.fn.hover({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      }),
  
      '&:active': theme.activeStyles,
    },
  
    dropdownFooter: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      margin: -theme.spacing.md,
      marginTop: theme.spacing.sm,
      padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
      paddingBottom: theme.spacing.xl,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
    },
  
    hiddenMobile: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },
  
    hiddenDesktop: {
      color:'white',
      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },

    registerButton:{
      backgroundColor: 'white',
      color:'#40005b',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, .8)',
      },
    },
    loginButton:{
      backgroundColor: 'transparent',
      borderColor:'white',
      color:'white',
      '&:hover': {
        // backgroundColor: 'rgba(255, 255, 255, .1)',
        // borderColor:'white',
        // borderStyle: 'solid',
        // borderWidth:'0px 0px 1.5px 0px',
        // // backgroundColor: 'transparent',
        // borderRadius: 0
      },
    },
    langButton:{
      backgroundColor: 'transparent',
      color:'white',
      borderColor:'white',
      '&:hover': {
        opacity: '90%',
        backgroundColor: 'transparent',
      },
    },
    image:{
      width: '30px',
      height: '30px',
    },
    closeButton:{
      color:'white'
    }
  }));
  
  export function Navbar() {
    const { t, i18n } = useTranslation();
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const  isLoggedIn:boolean|undefined = useAppSelector((state) => state.authState?.isLoggedIn)
    const  userInfoPopoverOpened:boolean|undefined = useAppSelector((state) => state.modalState?.isUserInfoPopoverOpen)
    const  cartPopoverOpened:boolean|undefined = useAppSelector((state) => state.modalState?.isCartPopoverOpen)
    const  isModalOpen:boolean = useAppSelector((state) => state.modalState?.isMembershipModalOpen)
    const  isLoginModalOpen:boolean = useAppSelector((state) => state.modalState?.isLoginModalOpen)


    const [cancelSubscription, response] = useCancelSubscriptionMutation()

    const  selectedItem = useAppSelector((state) => {
      if(state.priceState?.selected_sub_type !== undefined ){
        // console.log("Selected price")
        // console.log(state.priceState.selected_sub_type)
        return state.priceState?.selected_sub_type
    }})

    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const { classes, theme, cx } = useStyles();
    let navigate = useNavigate();
    const dispatch = useAppDispatch()

    useEffect(() => {
    }, [i18n])
    

    const successNotification = () => {
      showNotification({
        title: 'Success',
        message: "You're unsubcribed from CereMeet",
        styles: (theme) => ({
          root: {
            backgroundColor: theme.colors.green[6],
            borderColor: theme.colors.green[6],
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
            '&:hover': { backgroundColor: theme.colors.green[7] },
          },
        }),
      })
      
    }
  
    const errorNotification = () => {
      showNotification({
        title: 'Error',
        message: 'Problem occured during the process!',
        styles: (theme) => ({
          root: {
            backgroundColor: theme.colors.red[6],
            borderColor: theme.colors.red[6],
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
            '&:hover': { backgroundColor: theme.colors.red[7] },
          },
        }),
      })
      
    }

    const cancelUserSubscription = async() => {
      try {
          dispatch(loaderIsOn())
          await cancelSubscription('');
          closeModal()
          setTimeout(() => {
            successNotification()
            dispatch(loaderIsOff())
            navigateToHome()
          }, 2500);
        } catch (error:any) {
          setTimeout(() => {
            dispatch(loaderIsOff())
          }, 2500);
          errorNotification()
          console.error('rejected ', error);
        }
    }

    console.log(i18n.resolvedLanguage)
    let location = useLocation();
    // console.log(location)

    function handleUserPopover(){
      if(userInfoPopoverOpened === true){
        dispatch(userInfoPopoverIsOff())
      }
      else if(userInfoPopoverOpened === false){
        dispatch(userInfoPopoverIsOn())
      }
    }
    function handleCartPopover(){
      if(cartPopoverOpened === true){
        dispatch(cartPopoverOpenIsOff())
      }
      else if(cartPopoverOpened === false){
        dispatch(cartPopoverOpenIsOn())
      }
    }
    function closeModal(){
      dispatch(membershipModalIsOff())
      dispatch(userInfoPopoverIsOn())
    }
    function closeLoginModal(){
      dispatch(loginModalIsOff())
    }
    function navigateToHome(){
      // event.preventDefault();
      console.log("To home")
      navigate("/", { replace: true });
    } 
    function navigateToFaq(){
      // event.preventDefault();
      console.log("To faq")
      navigate("/faq", { replace: true });
    } 
    function navigateToPayment(){
      // event.preventDefault();
      console.log("To faq")
      navigate("/payment", { replace: true });
      handleCartPopover()
    } 
    function navigateToLogin(){
      // event.preventDefault();
      console.log("To login")
      navigate("/login", { replace: true });
    }
    function navigateToRegister(){
      // event.preventDefault();
      console.log("To pricing")
      navigate("/register", { replace: true });
    } 
    function navigateToLogin2(){
      // event.preventDefault();
      console.log("To login")
      navigate("/login", { replace: true });
      closeDrawer()
    }
    function navigateToRegister2(){
      // event.preventDefault();
      console.log("To pricing")
      navigate("/register", { replace: true });
      closeDrawer()
    } 
  

    return (
      <Box className={classes.container}>
        <Header height={'auto'} px='lg' sx={{width:'100%', backgroundColor:'transparent', border:'none', minHeight:60}}>
          <Group position="apart" sx={{ height: '100%', position:'relative' }}>
            {/* <MantineLogo size={30} /> */}
            <div className='flex flex-row items-center '>
              <a href="/" className='hover:cursor-pointer'>
                <img alt='CereLogo' className='w-24 h-12 lg:w-36 lg:h-20 lg:mt-4' src={CereLogo} />
              </a>
              {/* <Text onClick={navigateToHome} className={classes.logo} >
                Cere
              </Text> */}
            </div>
            <Group sx={{ height: '100%', position:'relative', placeSelf:'center' }} spacing={0} className={classes.hiddenMobile}>
              <a href='/#pricing'>
                <Button className={classes.link}>
                {t("components.navbar.main.text1")}
                </Button>
              </a>
              <a href='/#contact'>
                <Button className={classes.link}>
                {t("components.navbar.main.text2")}
                </Button>
              </a>
              <Button onClick={navigateToFaq} className={classes.link}>
              {t("components.navbar.main.text3")}
              </Button>
              <a href={t("components.navbar.main.link")} target="_blank" rel="noreferrer">
                <Button className={classes.link}>
                {t("components.navbar.main.text4")}
                </Button>
              </a>
              {/* <a href={t("components.navbar.main.link")}>
                <Button className={classes.link}>
                {t("components.navbar.main.text4")}
                </Button>
              </a> */}
              <a href={"https://ceremeet.com"} target="_blank" rel="noreferrer">
                <Button className={classes.link2}>
                {t("components.navbar.main.text5")}
                </Button>
              </a>
            </Group>

            <Group sx={{ display:'flex', flexDirection:'row', minWidth: '180px', justifyContent:'flex-end'}} className={classes.hiddenMobile}>
              {<>
                <Menu transition="pop-top-right" position="bottom" withinPortal>
                  <Menu.Target>
                    <Button className={classes.langButton} mr={0} px={8}>
                      {i18n.resolvedLanguage === 'en' ? 'EN' : 'TR'}{/* <IconWorld color='white' size={28} stroke={2} /> */}
                      <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3 pl-1 fill-slate-100' viewBox="0 0 384 512">
                        <path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                      </svg>
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                    onClick={()=>{
                      i18n.changeLanguage('en-US')
                      window.location.reload()
                  }}
                    >
                      English
                    </Menu.Item>
                    <Menu.Item
                    onClick={()=>{
                      i18n.changeLanguage('tr-TR')
                      window.location.reload()
                    }}
                    >
                      Turkish
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              {
                isLoggedIn === true ? 
                <>
                  <Popover opened={cartPopoverOpened} arrowOffset={15} offset={8} shadow={'md'} position="bottom-end" withArrow  transition="pop">
                    <Popover.Target >
                      <Indicator label={selectedItem ? 1 : 0} color={'teal'} showZero={false} inline size={16}>
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={handleCartPopover} className='w-7 h-7 fill-white hover:fill-[] hover: cursor-pointer' viewBox="0 0 576 512">
                          <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                        </svg>
                      </Indicator>
                    </Popover.Target>
                    <Popover.Dropdown sx={{paddingTop:'1rem',paddingBottom:'1rem'}}>
                      <BasketPopupItem/>
                      <Button onClick={navigateToPayment} color={'teal'} variant='light' fullWidth>{t("components.navbar.popover1.btn1")}</Button>
                    </Popover.Dropdown>
                  </Popover>
                  <Popover opened={userInfoPopoverOpened} arrowOffset={15} offset={8} shadow={'md'} position="bottom-end" withArrow  transition="pop">
                    <Popover.Target >
                      <svg xmlns="http://www.w3.org/2000/svg" onClick={handleUserPopover} className='w-7 h-7 fill-white hover: cursor-pointer' viewBox="0 0 512 512">
                        <path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM256 272c39.8 0 72-32.2 72-72s-32.2-72-72-72s-72 32.2-72 72s32.2 72 72 72z"/>
                      </svg>
                    </Popover.Target>
                    <Popover.Dropdown>
                      {/* <Text sx={{width: '120px'}} size="sm">This is uncontrolled popover, it is opened when button is clicked</Text> */}
                      <UserInfoAction />
                    </Popover.Dropdown>
                  </Popover>
                  </>
                :
                <>
                  {location.pathname === '/login' ? <Button className='invisible' onClick={navigateToLogin} variant="default">Log in</Button> : <Button className={classes.loginButton} onClick={navigateToLogin} variant="outline">{t("components.navbar.right.text1")}</Button>}
                  {location.pathname === '/register' ? <Button className='invisible' onClick={navigateToRegister} variant='filled' color={"dark"} >Sign up</Button> : <Button className={classes.registerButton}  onClick={navigateToRegister} >{t("components.navbar.right.text2")}</Button>}
                </>
              }
              </>
              }
            </Group>
  
            <Burger opened={drawerOpened} color={'white'} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          </Group>
        </Header>
  
        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          // styles={{closeButton:{color:'teal'}, root:{opacity:'95%'}}}
          styles={{closeButton:{color:'teal'}}}
          className={classes.hiddenDesktop }
          zIndex={1000000}
        >
          <ScrollArea sx={{ height: 'calc(100vh - 60px)' }}>
            {/* <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} /> */}
  
            <a href="/" className={classes.link} onClick={closeDrawer}>
              {t("components.navbar.drawer.text1")}
            </a>
            <a href="/#pricing" className={classes.link} onClick={closeDrawer}>
              {t("components.navbar.drawer.text2")}
            </a>
            <a href="/#contact" className={classes.link} onClick={closeDrawer}>
              {t("components.navbar.drawer.text3")}
            </a>
            <a href="/faq" className={classes.link} onClick={closeDrawer}>
              {t("components.navbar.drawer.text4")}
            </a>
            <a href={t("components.navbar.drawer.link")} className={classes.link} target="_blank" rel="noreferrer" >
              {t("components.navbar.drawer.text5")}
            </a>
            <a href="https://ceremeet.com" className={classes.link} target="_blank" rel="noreferrer"  >
              {t("components.navbar.main.text5")}
            </a>
            <a className={classes.link} 
              onClick={i18n.resolvedLanguage === 'en' ? ()=>{
                i18n.changeLanguage('tr-TR')
                window.location.reload();
              } : ()=>{
                i18n.changeLanguage('en-US') 
                window.location.reload()
              } }>
            {
            (i18n.resolvedLanguage === 'en' ? 'Turkish' : 'English' )
            }
            </a>
            
            {/* <Collapse in={linksOpened}>{links}</Collapse> */}
            {/* <a href='/#pricing'>
              <Button className={classes.link}>
                Pricing
              </Button>
            </a>
            <a href='/#contact'>
              <Button className={classes.link}>
                Contact Us
              </Button>
            </a> */}
  
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

            {
              isLoggedIn === true ?
                <div className='flex flex-col '>
                  <UserInfoAction /> 
                </div>
                :
                <div className='w-full flex flex-col px-6 self-end '>
                  <Button variant="default" my={6} onClick={navigateToLogin2}>{t("components.navbar.right.text1")}</Button>
                  <Button color={'teal'} my={6} onClick={navigateToRegister2}>{t("components.navbar.right.text2")}</Button>
                </div>
            }
            
  
          </ScrollArea>
        </Drawer>

        <Modal
          opened={isModalOpen}
          onClose={closeModal}
          title={t("components.navbar.modal1.text3")}
          size={'auto'}
          zIndex={8000}
          sx={{alignContent:'center'}
        }
        >
          {/* <p className='px-2'>You are going to downgrade your <span className='font-bold text-[#009999]'>CereMeet </span> account. <br/>Are you sure ?</p> */}
          <p className='px-2'>{t("components.navbar.modal1.text1")} <br/>{t("components.navbar.modal1.text2")}</p>
          <div className='flex flex-row justify-between w-full self-center'>
            <Button onClick={cancelUserSubscription} fullWidth variant="filled" color={'dark'} mt="md" mx={4}>
            {t("components.navbar.modal1.btn1")}
            </Button>
            <Button onClick={closeModal} fullWidth variant="filled" color={'teal'} mt="md" mx={4}>
            {t("components.navbar.modal1.btn2")}
            </Button>
          </div>
        </Modal>

        <Modal
          opened={isLoginModalOpen}
          onClose={closeLoginModal}
          title={t("components.navbar.modal2.text3")}
          size={'auto'}
          zIndex={8000}
          sx={{alignContent:'center'}
        }
        >
          {/* <p className='px-2'>To buy <span className='font-bold text-[#009999]'>CereMeet </span> products<br/>You need to login!</p> */}
          <p className='px-2'>{t("components.navbar.modal2.text1")}<br/>{t("components.navbar.modal2.text2")}</p>
          <div className='flex flex-row justify-between w-full self-center'>
            <Button onClick={()=>{
              navigateToLogin()
              dispatch(loginModalIsOff())
            }} 
              fullWidth variant="filled" color={'dark'} mt="md" mx={4}>
              {t("components.navbar.modal2.btn1")}
            </Button>
            <Button onClick={closeLoginModal} fullWidth variant="filled" color={'teal'} mt="md" mx={4}>
            {t("components.navbar.modal2.btn2")}
            </Button>
          </div>
        </Modal>

        {/* return policy popup */}
      </Box>
    );
  }

