import React, { useEffect, useState } from 'react'

import { Avatar, Text, Button, Paper, Modal } from '@mantine/core';
import { useAppSelector, useAppDispatch } from '../../../app/store';
import { useNavigate } from 'react-router-dom';
import { useLogoutUserMutation } from '../../../services/authApi';
import { loaderIsOff, loaderIsOn, membershipModalIsOff, membershipModalIsOn, userInfoPopoverIsOn } from '../../../slices/modalSlice';
import { deleteToken } from '../../../slices/authSlice';
import { userInfoPopoverIsOff } from '../../../slices/modalSlice';
import { useGetUserInfoQuery } from '../../../services/userApi';
import { string } from 'yup';
import { useTranslation } from 'react-i18next';

// interface UserInfoActionProps {
//   name?: string;
//   email?: string;
//   membership?: string;
// }

export function UserInfoAction() {
  const dispatch = useAppDispatch()
  const [ logoutUser, response] = useLogoutUserMutation()
  const { t, i18n } = useTranslation();
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetUserInfoQuery()

  useEffect(() => {
    checkTimeoutAndLogout(isError)
    refetch()
  }, [isLoading,isSuccess,isError])
  
  const [isMembershipInfoActive, setIsMembershipInfoActive] = useState(false)
  
  // const  ProfileInfo = useAppSelector((state) => {
  //   if(state.userState?.data.user !== undefined ){
  //     console.log(state.userState?.data)
  //     return state.userState?.data.user
  // }})

  const  isLoggedIn:boolean|undefined = useAppSelector((state) => state.authState?.isLoggedIn)
  
  
  let navigate = useNavigate();

  function openModal(){
    dispatch(membershipModalIsOn())
    dispatch(userInfoPopoverIsOff())
  }

  const checkTimeoutAndLogout = (isError:boolean) => {
    if(isError){
      handleLogoutUser()
    }
  }

  async function handleLogoutUser(){
    try {
      dispatch(loaderIsOn())
      const payload = await logoutUser().unwrap()
      setTimeout(() => {
        navigate("/", { replace: true });
        dispatch(loaderIsOff())
      }, 1500);
    } catch (error:any) {
        dispatch(userInfoPopoverIsOff())
        dispatch(deleteToken())
        setTimeout(() => {
          navigate("/", { replace: true });
          dispatch(loaderIsOff())
        }, 2500);
      console.log("error 234")
    }
    // NotificationHandler('Success','You logged in. Welcome to CereMeet!')
  }

  function navigateToUpdatePwd(){
    // event.preventDefault();
    console.log("To to updatepwd")
    navigate("/forgotpassword", { replace: true });
  } 

  return (
    <Paper
      radius="md"
      p="lg"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minWidth: 275,
        alignItems:'center',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
      })}
    >
      {isLoggedIn === true ? 
        isSuccess === true ?  
          <>
            <Avatar src={`https://avatars.dicebear.com/api/initials/${data.data.user.name}.svg?background=%23009999`} size={100} mx="auto" />
            <Text align="center" size="lg" weight={500} mt={'2rem'}>
              { data.data.user.name }
            </Text>
            <Text mt='md' align="center" color="dimmed" size="sm">
              { data.data.user.email}
            </Text>
            <Text mt='xs' align="center" color="dimmed" size="sm" mb={'2rem'}>
              { data.data.user.membership}
            </Text>

            {isMembershipInfoActive === false ?
              <> 
                {
                  data.data.user.membership === 'free' ? null :   
                    <Button onClick={()=>setIsMembershipInfoActive(true)} variant="filled" color={'grape'} fullWidth mt={'md'}>
                      {t("components.navbar.profile.btn1")}
                    </Button>
                }
                <Button onClick={navigateToUpdatePwd} variant="filled" color={'teal'} fullWidth mt={'md'}>
                  {t("components.navbar.profile.btn2")}
                </Button>
                <Button onClick={handleLogoutUser} fullWidth variant="filled" color={'dark'} mt="md">
                  {t("components.navbar.profile.btn3")}
                </Button>
              </>
              :
              <> 
                <Button onClick={openModal} variant="filled" color={'teal'} fullWidth mt={'2rem'}>
                {t("components.navbar.profile.btn4")}
                </Button>
                <Button onClick={()=>setIsMembershipInfoActive(false)} fullWidth variant="filled" color={'dark'} mt="md">
                {t("components.navbar.profile.btn5")}
                </Button>
              </>
            }
          </>
          
        :
        <Text align="center" color={'teal'} size="lg" weight={500} mt={'2rem'}>
          {t("components.navbar.profile.text1")}
        </Text>
        :
        <Text align="center" color={'teal'} size="lg" weight={500} mt={'2rem'}>
          {t("components.navbar.profile.text2")}
        </Text>
      }
    </Paper>
  );
}

