import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
  createStyles,
  Text,
  Group,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useRegisterUserMutation } from "../../../services/authApi";
import { loaderIsOff, loaderIsOn } from "../../../slices/modalSlice";
import { useAppDispatch } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { setAuthCredentials } from "../../../slices/authSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  label: {
    color: "white",
  },
  button: {
    borderColor: "#009999",
    backgroundColor: "#009999",
    "&:hover": {
      boxShadow: "1px 1px 2px 2px #009999",
      backgroundColor: "#009999",
      border: "none",
    },
  },
  input: {
    "&:focus": {
      borderColor: "#009999",
    },
    "&:hover": {
      boxShadow: "1px 1px 2px 2px #009999",
      borderStyle: "none",
      border: "none",
    },
  },
}));

interface formValues extends Yup.InferType<typeof validationSchema> {
  name: string;
  password: string;
  email: string;
  passwordConfirm: string;
  lang: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Takma adınız boş olamaz!"),
  email: Yup.string().email().required("Mail adresiniz boş olamaz!"),
  password: Yup.string()
    .min(8)
    .max(20)
    .required("Şifreniz en az 8 - en fazla 20 karakterden oluşmalı."),
  passwordConfirm: Yup.string()
    .required("Şifrenizi tekrar giriniz.")
    .oneOf([Yup.ref("password"), null], "Şifreleriniz aynı değil!"),
  lang: Yup.string().max(20).required(),
});

export function NewRegisterForm() {
  const { classes } = useStyles();
  const [registerUser, response] = useRegisterUserMutation();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSuccess = async (codeResponse: any) => {
    console.log(codeResponse);
    await fetch(
      `https://api.ceremeet.com/api/auth/google/callback/code/${codeResponse.access_token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    ).then((res) => res.json()).
    then((data) => {
      console.log(data);
      dispatch(setAuthCredentials(data));
      navigateToHome();
    });
  };

  const onFailure = (res: any) => {
    console.log("Login failed. Error:", res.error);
  };

  const options = {
    onSuccess,
    onFailure,
    redirectUri: "https://api.ceremeet.com/api/auth/google/callback",
  };

  const login = useGoogleLogin(options);

  function navigateToHome() {
    // event.preventDefault();
    console.log("To home");
    navigate("/", { replace: true });
  }

  const successNotification = (title: string, message: string) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.green[6],
          borderColor: theme.colors.green[6],
          "&::before": { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          "&:hover": { backgroundColor: theme.colors.green[7] },
        },
      }),
    });
  };

  const errorNotification = (title: string, message: any) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.red[6],
          borderColor: theme.colors.red[6],
          "&::before": { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          "&:hover": { backgroundColor: theme.colors.red[7] },
        },
      }),
    });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
        lang: "en-US",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(
          t("components.forms.register.validation.input1")
        ),
        email: Yup.string()
          .email(t("components.forms.register.validation.input2format"))
          .required(t("components.forms.register.validation.input2")),
        password: Yup.string()
          .min(8, t("components.forms.register.validation.input3min"))
          .max(25, t("components.forms.register.validation.input3max"))
          .required(t("components.forms.register.validation.input3")),
        passwordConfirm: Yup.string()
          .required(t("components.forms.register.validation.input4"))
          .oneOf(
            [Yup.ref("password"), null],
            t("components.forms.register.validation.input5")
          ),
        lang: Yup.string().max(20).required(),
      })}
      onSubmit={async (
        values: formValues,
        { setSubmitting }: FormikHelpers<formValues>
      ) => {
        //sends form values to the server then processes response data on handleSubmit function
        try {
          dispatch(loaderIsOn());
          const payload = await registerUser(values).unwrap();
          setTimeout(() => {
            successNotification(
              t(`components.successes.title`),
              t(`components.successes.register.text1`)
            );
            dispatch(loaderIsOff());
          }, 2000);
          console.log("fulfilled ", payload);
        } catch (error: any) {
          errorNotification(
            t(`components.errors.title`),
            t(`components.errors.${error.data.errorCode.toString()}`)
          );
          console.error("rejected ", error);
        }
      }}
    >
      <Form style={{ zIndex: 1000 }}>
        <div className="-z-0  2xl:mr-38 sm:mr-8 lg:mr-16 md:mr-12">
          <Container
            size={420}
            px={12}
            pt={20}
            pb={25}
            my={0}
            sx={{
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              borderRadius: "25px",
              borderWidth: "0px",
              minWidth: "340px",
              borderColor: "white",
              minHeight: "auto",
            }}
          >
            <Title
              align="center"
              sx={(theme) => ({ fontWeight: 600, color: "white", px: 2 })}
            >
              {t("components.forms.register.header")}
            </Title>

            <Paper
              py={20}
              px={20}
              mt={30}
              radius="md"
              sx={{ backgroundColor: "transparent", height: "auto" }}
            >
              <Field name="name">
                {({ field, form }: any) => (
                  <TextInput
                    {...field}
                    error={
                      form.touched.name && form.errors.name ? (
                        <ErrorMessage name={"name"} />
                      ) : null
                    }
                    classNames={classes}
                    label={t("components.forms.register.inputName")}
                    placeholder="John Doe"
                    required
                    mt="md"
                  />
                )}
              </Field>
              <Field name="email">
                {({ field, form }: any) => (
                  <TextInput
                    {...field}
                    classNames={classes}
                    name="email"
                    error={
                      form.touched.email && form.errors.email ? (
                        <ErrorMessage name={"email"} />
                      ) : null
                    }
                    label={t("components.forms.register.inputEmail")}
                    placeholder="mail@ceremeet.com"
                    required
                    mt="md"
                  />
                )}
              </Field>
              <Field name="password">
                {({ field, form }: any) => (
                  <PasswordInput
                    {...field}
                    classNames={classes}
                    name="password"
                    error={
                      form.touched.password && form.errors.password ? (
                        <ErrorMessage name={"password"} />
                      ) : null
                    }
                    label={t("components.forms.register.inputPwd")}
                    placeholder={t("components.forms.register.placeholder1")}
                    mt="md"
                    required
                  />
                )}
              </Field>
              <Field name="passwordConfirm">
                {({ field, form }: any) => (
                  <PasswordInput
                    {...field}
                    classNames={classes}
                    name="passwordConfirm"
                    error={
                      form.touched.passwordConfirm &&
                      form.errors.passwordConfirm ? (
                        <ErrorMessage name={"passwordConfirm"} />
                      ) : null
                    }
                    label={t("components.forms.register.inputConPwd")}
                    placeholder={t("components.forms.register.placeholder2")}
                    mt="md"
                    required
                  />
                )}
              </Field>
              <Button
                className={classes.button}
                variant="filled"
                type="submit"
                fullWidth
                mt="xl"
                mb={24}
              >
                {t("components.forms.register.button.btnSubmit")}
              </Button>
              <hr className="mb-6" />
              <Group position="apart">
                <button type="button" className="bg-white hover:bg-gray-100 transition-all w-full h-12 rounded-full flex flex-row items-center px-10 justify-evenly text-[15px] text-[#636769] font-[600] font-roboto" onClick={() => login()}>
                  <svg viewBox="0 0 16 16" className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path fill="#4285F4" d="M14.9 8.161c0-.476-.039-.954-.121-1.422h-6.64v2.695h3.802a3.24 3.24 0 01-1.407 2.127v1.75h2.269c1.332-1.22 2.097-3.02 2.097-5.15z"/><path fill="#34A853" d="M8.14 15c1.898 0 3.499-.62 4.665-1.69l-2.268-1.749c-.631.427-1.446.669-2.395.669-1.836 0-3.393-1.232-3.952-2.888H1.85v1.803A7.044 7.044 0 008.14 15z"/><path fill="#FBBC04" d="M4.187 9.342a4.17 4.17 0 010-2.68V4.859H1.849a6.97 6.97 0 000 6.286l2.338-1.803z"/><path fill="#EA4335" d="M8.14 3.77a3.837 3.837 0 012.7 1.05l2.01-1.999a6.786 6.786 0 00-4.71-1.82 7.042 7.042 0 00-6.29 3.858L4.186 6.66c.556-1.658 2.116-2.89 3.952-2.89z"/>
                  </svg>
                  {t("components.forms.register.button.btnGoogle")}
                </button>
              </Group>
            </Paper>
          </Container>
        </div>
      </Form>
    </Formik>
  );
}
