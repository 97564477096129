import { forwardRef } from 'react';
import { Group, Avatar, Text, Select, createStyles } from '@mantine/core';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setSelectedAddress } from '../../slices/addressSlice';
import { useGetAllAddressInfoQuery } from '../../services/addressApi';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: 10,
    marginBottom: 14,
    width: 'auto',
  },

  input: {
    height: 'auto',
    marginTop: 6,
    borderRadius: 8,
    width: '20vw',
    fontSize: 16,
    minWidth: '300px',
    '&:focus': {
      boxShadow: "1px 1px 2px 2px white",
      borderStyle:'none'
    },
    '&:hover': {
      boxShadow: "1px 1px 2px 2px white",
      borderStyle:'none'
    },
  },

  item: {
    marginBottom: '6px',
    backgroundColor:'white',
    '&:hover': {
      backgroundColor:'#009999',
      color:'white'
    },
    '&:focus': {
      backgroundColor:'#009999',
      color:'white'
    },
    '&:active': {
      backgroundColor:'#009999',
      color:'white'
    },
    '&:enabled': {
      backgroundColor:'#009999',
      color:'white'
    },
  },

  label: {
    fontSize: 20,
    zIndex: 1,
    color:'white',
  },

  button:{
    backgroundColor:'#009999',
    color:'white',
    '&:hover': {
      backgroundColor:'#009999',
      color:'white'
    },
    '&:focus': {
      backgroundColor:'#009999',
      color:'white'
    },
    '&:active': {
      backgroundColor:'#009999',
      color:'white'
    },
  },
}));

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  id: string,
  created_at: string,
  updated_at: string,
  label: string,
  value: string,
  name: string,
  address: string,
  city: string,
  country: string,
  userId: string,
  zipCode: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ name, address, id, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} key={id}>
      <Group  noWrap>
        <div >
          <Text size="sm" className='font-semibold'>{name}</Text>
          <Text size="xs" opacity={0.65}> {address}</Text>
        </div>
      </Group>
    </div>
  )
);

export function PaymentAddressSelect() {

  const {
    data: addresses,
    isFetching,
    isLoading,
  } = useGetAllAddressInfoQuery('')
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch()

  // const  UserAdresses = useAppSelector((state) => {
  //   if(state.userState?.data.user !== undefined ){
  //     // console.log(state.userState?.data)
  //     return state.userState?.data.user.addresses
  // }})
  // console.log(UserAdresses)

  const setAddress = (value:string) => {
    console.log( "in set Address " + value)
    dispatch(setSelectedAddress(value))
  }

  const { classes } = useStyles();

  return (
    addresses.data.address ? 
    <Select
      label={t("pages.payment.form3.section1.text1")}
      placeholder={t("pages.payment.form3.section1.text2")}
      classNames={classes}
      itemComponent={SelectItem}
      data={addresses.data.address}
      searchable
      maxDropdownHeight={200}
      onChange={setAddress}
      nothingFound="Address not found!"
      filter={(value, item) =>
        item.name?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.address?.toLowerCase().includes(value.toLowerCase().trim())
      }
    />
    :
    <></>
  );
}