import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INewMeetingResponse, IPriceItem, IPricesDataContainer, IPricesGeneralState } from '../services/types';
import { string } from 'yup/lib/locale';

const initialState: any = {
    prices_list: null,
    selected_sub_type: null,
    sub_type_status: false,
  };

export const pricesSlice = createSlice({
  initialState,
  name: 'pricesSlice',
  reducers: {
    setPriceData: (state, action: PayloadAction<IPricesDataContainer>) => {
      state.prices_list = action.payload.data.prices;
    },
    setSelectedSubType: (state,action: PayloadAction<IPriceItem>) => {
        state.selected_sub_type = action.payload;
    },
    deleteSelectedSubType: (state) => {
        state.selected_sub_type = null;
    },
  },
});

export default pricesSlice.reducer;

export const { setPriceData, setSelectedSubType,deleteSelectedSubType } = pricesSlice.actions;