/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {Checkbox, createStyles} from '@mantine/core'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { deleteSelectedSubType, setSelectedSubType } from '../../../slices/pricesSlice';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({

  label: {
    color:'white',
  },
  input: {
    color:'black',
    borderWidth: '2px',
    borderColor:'#009999',
    '&:focus': {
      borderColor: '#009999',
      color:'black',
    },
    
    '&:checked': {
      backgroundColor: '#009999',
      borderColor: '#009999',
    },
    '&:active': {
      backgroundColor: '#009999',
      borderColor: '#009999',
    },
  },
  description:{
    color:'black'
  },

  button:{
    borderColor: '#009999',
    backgroundColor:'#009999',
    '&:hover': {
      boxShadow: "1px 1px 2px 2px #009999",
      backgroundColor:'#009999',
      border:'none'
    },
  },
  
}));
const PriceSelectionCard = ({subscriptionType, price,paymentInterval, selected, currencyCode ,onClick}:{subscriptionType:string, price:string, paymentInterval:string, currencyCode:string, selected:boolean, onClick:any}) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();  

  return (
    <section className="text-gray-600 body-font overflow-hidden bg-transparent rounded-xl " >
      <div className="container py-3 mx-auto">
        <div onClick={onClick} className=" hover: cursor-pointer hover:bg-opacity-90 w-full sm:w-9/12 mx-auto flex flex-wrap flex-row sm:min-w-[30vw] items-center bg-white px-1 rounded-xl pr-4 sm:pr-8 " >
          {/* <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="https://dummyimage.com/400x400"/> */}
          <div className='flex flex-row justify-center w-1/6 sm:w-1/6 object-cover object-center h-auto self-center'>
            <Checkbox checked={selected} classNames={classes} 
            />
          </div>
          <div className="sm:w-5/6 w-5/6 sm:py-4 mt-6 sm:mt-0  z-50 flex-row">
            <h2 className=" hidden sm:block text-sm title-font text-transparent tracking-widest">BRAND NAME</h2>
            <h1 className="text-lg text-gray-900 sm:text-3xl title-font font-medium mb-1">{subscriptionType +" " + t("pages.payment.form1.text3")}</h1>
            
            <div className="flex flex-row justify-end">
              <span className="title-font text-end font-semibold text-lg text-gray-900">{price}{paymentInterval}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PriceSelectionCard