import React, { useEffect, useState } from 'react'

const FeatureItem = ({title, description}:{title:string, description?:string}) => {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    
  }, [open])

  // onMouseOverCapture={() => setOpen(true) } onMouseOutCapture={() => setOpen(false) }
  return (
    <div className="lg:col-span-1 lg:row-span-1 p-2 w-full mb-2 h-40 relative "  onClick={() => setOpen(prev => !prev)}>
      <div className="bg-gray-900 bg-opacity-90 relative rounded flex p-4 h-full justify-center items-center mb-2 flex-col hover:bg-opacity-90 transition-all duration-150">
          {/* <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg> */}
          <span className={`font-semibold text-lg text-white text-center ${open === true || title === '' ? 'hidden' : 'block' }` }>{title}</span>
          <div className={`${open === false || title === '' ? 'hidden' : 'flex' } transition-all duration-150 rounded p-4 h-full sticky items-center z-50 right-0 left-0`}>
            <span className="w-fit text-center font-medium text-lg text-white transition-all duration-150">{description}</span>
          </div>
          <svg className={`w-4 h-4 absolute ml-3 mt-3 top-0 left-0 fill-slate-200 animate-pulse duration-[3000ms] ${open === true || title === '' ? 'hidden' : 'absolute' }`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"/>
          </svg>
      </div>
    </div>
  )
}

export default FeatureItem

{/* <div className="lg:col-span-1 lg:row-span-1 w-full mb-2 h-40 relative rounded" >
      <div className=" bg-gray-900 hover:bg-transparent hover:z-20 flex flex-row transition-all duration-200 justify-center items-center w-full h-full z-40 absolute top-0 right-0 left-0 bottom-0 p-2">
          <span className={`font-semibold text-lg text-white text-center w-full` }>{title}</span>
      </div>
      <div className={` bg-gray-900 flex flex-row transition-all duration-200 justify-center items-center w-full h-full absolute z-30 top-0 right-0 left-0 bottom-0 p-2`}>
        <span className="text-center font-medium text-lg text-white ">{description}</span>
      </div>
    </div> */}