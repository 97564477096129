import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/store';
import { IPriceItem, IPricesData } from '../../../services/types';
import { useGetPricesDataMutation } from '../../../services/pricesApi';
import { loginModalIsOn } from '../../../slices/modalSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ICurrencyCode = 'USD' | 'TRY'

const NewPricingSection = () => {

  const [ getPrices, response] = useGetPricesDataMutation()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation();

  const [currencyCode, setCurrencyCode] = useState<ICurrencyCode>('TRY') 

  const  pricesList = useAppSelector((state) => {
    if(state.priceState?.prices_list !== undefined ){
      // console.log("Selam price")
      // console.log(state.priceState.prices_list)
      return state.priceState?.prices_list
  }})

  return (
    <section className="text-white body-font w-full  min-h-screen z-50 mb-20 sm:snap-center" id='pricing'>
    <div className="container px-5 py-24 mx-auto w-auto">
      <div className="flex flex-col text-center w-full mb-20">
        <h1 className="sm:text-6xl text-[42px] font-extrabold title-font mb-2 text-[#009999]">{t("pages.home.pricing.header.title")}</h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-lg text-gray-100">{t("pages.home.pricing.header.description")}</p>
        <div className="flex mx-auto border-2  border-[#009999] rounded overflow-hidden mt-6">
          <button onClick={()=>setCurrencyCode('USD')} className={`py-1 px-4 ${currencyCode === 'USD' ? 'bg-[#009999]' :'focus:outline-none'} text-white`}>USD</button>
          <button onClick={()=>setCurrencyCode('TRY')} className={`py-1 px-4 ${currencyCode === 'TRY' ? 'bg-[#009999]' :'focus:outline-none'}`}>TRY</button>
        </div>
      </div>
      <div className="flex flex-wrap w-auto -m-4">
        
        <NewPricingCardStatic pricingText={t("pages.home.pricing.card1.header.title2")} pricingType={t("pages.home.pricing.card1.header.title1")} paymentInterval=''/>
        {pricesList?.map((item:any, index:any) => {
          if(item.paymentInterval === 'Monthly' && item.currencyCode === currencyCode ) {
            return <NewPricingCardPopular key={index} priceData={item}  currency={item.currencyCode} price={item.price} pricingType={t("pages.home.pricing.card2.header.title1")} paymentInterval={t("pages.home.pricing.card2.header.title2")}/>
          }else if(item.paymentInterval === 'Yearly' && item.currencyCode === currencyCode){
            return <NewPricingCardNormal key={index} priceData={item} currency={item.currencyCode} price={item.price}  pricingType={t("pages.home.pricing.card3.header.title1")} paymentInterval={t("pages.home.pricing.card3.header.title2")}/>
          }
          return null
        })}
        <NewPricingCardEnterprise pricingText={t("pages.home.pricing.card4.header.title2")} pricingType={t("pages.home.pricing.card4.header.title1")} paymentInterval=''/>
        <p className="lg:w-1/2 mx-auto leading-relaxed text-center text-sm text-gray-100 mt-8">{t("pages.home.pricing.refund.text1")}</p>
    
      </div>
    </div>
  </section>
  )
}
const NewPricingCardStatic = ({pricingType,pricingText,paymentInterval}:{pricingType:string,pricingText:string,paymentInterval:string}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="p-4 xl:w-1/4 md:w-1/2 w-full hover:scale-105 transition">
          <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden bg-white">
            <h2 className="text-sm tracking-widest title-font text-gray-600 mb-1 font-medium">{pricingType}</h2>
            <h1 className="text-5xl text-black leading-none flex items-center pb-4 mb-4 border-b border-gray-400">
              <span>{pricingText}</span>
              <span className="text-lg ml-1 font-normal text-gray-500">{paymentInterval}</span>
            </h1>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card1.items.text1")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card1.items.text2")}
            </p>
            {/* <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card1.items.text3")}
            </p> */}
            {/* <button className="flex items-center mt-auto text-white bg-[#2f0042] border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">Add to Cart
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button> */}
            {/* <p className="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p> */}
          </div>
        </div>
  )
}
const NewPricingCardPopular = ({pricingType,price,currency, priceData ,paymentInterval}:{pricingType:string,price:string,currency:string,paymentInterval:string, priceData:Object}) => {
  const  isLoggedIn:boolean|undefined = useAppSelector((state) => state.authState?.isLoggedIn)
  const dispatch = useAppDispatch()
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function openModal(){
    dispatch(loginModalIsOn())
  }

  function navigateToPayment(){
    // event.preventDefault();
    console.log("To faq")
    navigate("/payment", { replace: true });
  } 
  
  return (
    <div className="p-4 xl:w-1/4 md:w-1/2 w-full hover:scale-105 transition ">
          <div className="h-full p-6 rounded-lg border-2 border-[#009999] bg-transparent flex flex-col relative overflow-hidden">
            <span className="bg-[#009999] text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">{t("pages.home.pricing.card2.header.text1")}</span>
            <h2 className="text-sm tracking-widest title-font mb-1 font-medium">{t("pages.home.pricing.card2.header.title1")}</h2>
            <h1 className="text-5xl text-white leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
              <span>{currency ==='USD' ? `$${price}` : price+'₺' }</span>
              <span className="text-lg ml-1 font-normal text-gray-500">{paymentInterval}</span>
            </h1>
            <p className="flex items-center text-white mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card2.items.text1")}
            </p>
            <p className="flex items-center text-white mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card2.items.text2")}
            </p>
            <p className="flex items-center text-white mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card2.items.text3")}
            </p>
            <p className="flex items-center text-white mb-6">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card2.items.text4")}
            </p>
            <button onClick={ isLoggedIn === true ? navigateToPayment : openModal } className="flex items-center mt-auto text-white bg-[#009999] border-0 py-2 px-4 w-full focus:outline-none hover:bg-[#009999] rounded">{t("pages.home.pricing.card2.items.text5")}
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
            {/* <p className="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p> */}
          </div>
        </div>
  )
}

const NewPricingCardNormal = ({pricingType,price,currency, priceData, paymentInterval}:{pricingType:string,price:string,currency:string,paymentInterval:string, priceData:Object}) => {
  const  isLoggedIn:boolean|undefined = useAppSelector((state) => state.authState?.isLoggedIn)
  const dispatch = useAppDispatch()
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function openModal(){
    dispatch(loginModalIsOn())
  }

  function navigateToPayment(){
    // event.preventDefault();
    console.log("To faq")
    navigate("/payment", { replace: true });
  } 
  return (
    <div className="p-4 xl:w-1/4 md:w-1/2 w-full hover:scale-105 transition">
          <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden bg-white">
          <span className="bg-[#009999] text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">{t("pages.home.pricing.card2.header.text1")}</span>
            <h2 className="text-sm tracking-widest title-font text-gray-600 mb-1 font-medium">{pricingType}</h2>
            <h1 className="text-5xl text-black leading-none flex items-center pb-4 mb-4 border-b border-gray-400">
            <span>{currency ==='USD' ? `$${price}` : price+'₺' }</span>
              <span className="text-lg ml-1 font-normal text-gray-500">{paymentInterval}</span>
            </h1>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>{t("pages.home.pricing.card3.items.text1")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card3.items.text2")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card3.items.text3")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card3.items.text4")}
            </p>
            <button onClick={ isLoggedIn === true ? navigateToPayment : openModal } className="flex items-center mt-auto text-white bg-[#2f0042] border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">{t("pages.home.pricing.card3.items.text6")}
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
            {/* <p className="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p> */}
          </div>
        </div>
  )
}

const NewPricingCardEnterprise = ({pricingType,pricingText,paymentInterval}:{pricingType:string,pricingText:string,paymentInterval:string}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="p-4 xl:w-1/4 md:w-1/2 w-full hover:scale-105 transition">
          <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden bg-white">
            <h2 className="text-sm tracking-widest title-font text-gray-600 mb-1 font-medium">{pricingType}</h2>
            <h1 className="text-5xl text-black leading-none flex items-center pb-4 mb-4 border-b border-gray-400">
              <span>{pricingText}</span>
              <span className="text-lg ml-1 font-normal text-gray-500">{paymentInterval}</span>
            </h1>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card4.items.text1")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card4.items.text2")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card4.items.text3")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card4.items.text4")}
            </p>
            <p className="flex items-center text-black mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {t("pages.home.pricing.card4.items.text5")}
            </p>
            {/* <button className="flex items-center mt-auto text-white bg-[#2f0042] border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">Add to Cart
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button> */}
            {/* <p className="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p> */}
          </div>
        </div>
  )
}


export default NewPricingSection