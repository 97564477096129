import { Container, Title, Accordion, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
  wrapper: {
    // paddingTop: theme.spacing.xl * 1.5,
    
    [theme.fn.smallerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 1.5,
      minWidth: 'full',
      width: '100vw',
      marginTop: '4rem',
      zIndex: 10,
    },
    [theme.fn.largerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 1.5,
      minHeight: 650,
      minWidth: 650,
      width: '60vw',
      maxWidth: 800,
      marginTop: '4rem',
      zIndex: 10,
    },
  },

  title: {
    marginBottom: theme.spacing.xl * 1.6,
    color: 'white'
  },

  item: {
    borderRadius: theme.radius.sm,
    marginBottom: theme.spacing.lg,
    // backgroundColor: '#a64dff',

    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  
}));

const faqENData = [
  {
    question : 'Who is Cere?',
    answer : "Cere is our intelligent AI friend that uses artificial intelligence to provide users with knowledge and recommendations based on their interests. Whether you're a tech enthusiast, a fashion-savvy, or a lifetime learner, Cere has something to offer you.  "
  },
  {
    question : 'What do I need to chat with Cere?',
    answer : 'To chat with Cere, all you need is a device (computer, phone, tablet) with an internet connection.'
  },
  {
    question : "What are Cere's natural language processing features? ",
    answer : 'Cere allows users to ask questions and receive answers in written or spoken form, thanks to its natural language processing features. Cere uses advanced AI technologies to understand conversations, provide meaningful responses, and guide users in situations that require human intervention.'
  },
  {
    question : 'What topics can I ask Cere questions about?',
    answer : "You can ask Cere questions on almost any topic, including general knowledge, entertainment, technology, and more. However, please note that Cere's knowledge is based on the training data it has been provided and its knowledge is up to date as of late 2022."
  },
  {
    question : 'Is there a fee to use the Cere application?',
    answer : 'Cere offers both free and paid versions. The free version provides users with a limited number of features and storage space, while the paid version offers a wider range of features and more storage space.'
  },
  {
    question : 'Is all the information provided by Cere correct? ',
    answer : 'No, Cere may occasionally provide inaccurate information or construct illogical sentences. It is important to note that no AI-generated responses can be deemed 100% accurate. Cere is developed based on an advanced autoregressive language model and has been refined through global efforts. This application serves primarily as an entertainment tool, and Cerebrum Tech does not assume responsibility for the accuracy or consistency of the information provided by Cere.'
  },
  {
    question : "Why doesn't Cere answer some of my questions?",
    answer : 'Cere follows certain ethical rules when forming sentences and answering questions. You may not receive answers from Cere when asking questions about language, religion, belief, race, ethnicity, gender, political views, etc. Cere prefers not to discuss topics that could cause division and unrest among people in society.'
  },
  {
    question : 'Cere gave a wrong answer to my question. What should I do?',
    answer : 'Cere can sometimes produce incorrect answers or illogical sentences. In such cases, you can teach Cere the correct information or email our team with information about the question you asked and the answer Cere provided.'
  },
  {
    question : 'What can I do if I encounter any problems with Cere?  ',
    answer : 'If you encounter any problems during your use of Cere, you can visit the frequently asked questions section to find answers to your questions. If your questions and issues still remain unresolved, you can fill out the contact form or email your questions to our support team at info@cerebrumtechnologies.com.'
  },
  {
    question : "How was Cere developed?",
    answer : "Cere was developed by fine-tuning OpenAI's GPT-3 and GPT-4 models, making various adjustments to create a distinct character. The goal during the retraining process was for Cere to access current information in a modular fashion and to be a more approachable character."
  },
]
const faqEN_EnterpriseData = [
  {
    question : "What services does Cere offer for corporate clients? ",
    answer : "Cere provides support to corporate clients using artificial intelligence and natural language processing technologies. It offers customizable solutions in the areas of communication and productivity to optimize business processes and enhance employee performance. Cere allows the creation of more engaging and personalized customer experiences. "
  },
  {
    question : "Which sectors does Cere serve? ",
    answer : "Cere is an AI technology platform that can serve many different sectors, including finance, healthcare, retail, e-commerce, logistics, manufacturing, tourism, and more. As a customizable platform, Cere can be tailored and used according to the needs of the sector."
  },
  {
    question : "What are Cere's data security policies?  ",
    answer : "Cere takes various measures to ensure the security of customer data. These include data encryption, strict authentication procedures, and controlling data access permissions. For more information, you can read the user agreement and privacy policy."
  },
  {
    question : "How does Cere price its services for corporate clients?",
    answer : "Cere offers a pricing model that varies according to the needs of the customer. Clients can pay for a specific service package or feature set, or they can contact Cere for a customized pricing option. "
  },
  {
    question : "In which languages does Cere provide language support for corporate clients? ",
    answer : "Cere offers natural language processing services in multiple languages. Language support may vary depending on the client's needs and usage scenarios."
  },
]

const faqTRData = [
  {
    question : 'Cere nedir?',
    answer : 'Cere, kullanıcıların ilgi alanlarına göre bilgi ve öneriler sunan bir yapay zekadır. Teknoloji meraklısı, moda tutkunu ya da sürekli bilgi keşfine açık biriyseniz, Cere size özelleştirilmiş içerik ve tavsiyelerle destek sağlar. Cere, kullanıcıların deneyimini zenginleştirmeyi ve bilgi dağarcığını geliştirmeyie yönelik olarak tasarlanmıştır.'
  },
  {
    question : "Cere’yle sohbet edebilmek için neye ihtiyacım var?",
     answer : "Cere'yle sohbet edebilmek için internet bağlantısı olan herhangi bir cihaza (bilgisayar, telefon, tablet) sahip olmanız yeterlidir."
  },
  {
    question : "Cere'nin doğal dil işleme özellikleri nelerdir?",
    answer : 'Cere, doğal dil işleme özellikleri sayesinde kullanıcıların yazılı veya sözlü olarak sorular sormasına ve cevaplar almasına olanak tanır. Cere, konuşmaları anlamak, anlamlı yanıtlar vermek ve gerektiğinde insan müdahalesi gerektiren durumlarda kullanıcıyı yönlendirmek için gelişmiş yapay zeka teknolojileri kullanır.'
  },
  {
    question : "Cere’ye hangi konularla ilgili sorular sorabilirim? ",
    answer : "Cere'ye hemen hemen her konu hakkında soru sorabilirsiniz; genel bilgi, eğlence, teknoloji gibi konularda geniş bir bilgi yelpazesi vardır. Ancak, lütfen Cere'nin bilgi tabanının sağladığı eğitim verilerine dayandığını ve bilgilerinin 2022'nin sonuna kadar güncel olduğunu unutmayın."
  },
  {
    question : 'Cere uygulamasını kullanmak ücretli mi?',
    answer : "Cere'nin, hem ücretsiz hem de ücretli sürümleri mevcuttur. Ücretsiz sürümde, kullanıcılara sınırlı sayıda özellik ve sınırlı veri saklama alanı sunulurken, ücretli sürümde daha geniş bir özellik yelpazesi ve daha büyük veri saklama alanı sunulur."
  },
  {
    question : "Cere’nin verdiği her bilgi doğru mudur?",
    answer : "Hayır, Cere'nin verdiği her bilgi doğru olmayabilir. Bazen yanlış cevaplar verebilir veya mantıksız cümleler kurabilir. Günümüzde hiçbir yapay zekanın sağladığı cevaplar %100 doğru olarak kabul edilmemektedir. Cere, derin öğrenme teknolojisi kullanan bir özbağlanımlı dil modeli üzerine geliştirilmiştir. Bu uygulama eğlence amaçlıdır ve Cere tarafından verilen bilgilerin doğruluğu ve tutarlığından Cerebrum Tech sorumlu değildir."
  },
  {
    question : 'Cere neden bazı sorularıma cevap vermiyor?',
    answer : 'Cere cümleler kurarken ve kendisine sorulan soruları cevaplarken bazı etik kuralları takip etmektedir. Cere’ye dil,din,mezhep,inanç,ırk,etnik köken,toplumsal cinsiyet, siyasi görüş vb. konular hakkında soracağınız sorular karşısında Cere’den cevap alamamanız olasıdır. Cere toplumdaki insanlar arasında ayrılığa ve huzursuzluğa sebebiyet verebilecek konular hakkında konuşmamayı tercih etmektedir.'
  },
  {
    question : 'Cere sorduğum soruya yanlış cevap verdi. Ne yapmalıyım? ',
    answer : 'Cere bazı durumlarda yanlış cevaplar veya mantıksız cümleler üretebiliyor. Böyle durumlarda Cere’ye doğru bilgiyi öğretebilir veya ekibimize e-posta üzerinden sorduğunuz soru ve Cere’nin verdiği cevaplarla ilgili bilgi verebilirsiniz.'
  },
  {
    question : "Cere ile ilgili herhangi bir sorunla karşılaşırsam ne yapabilirim?",
    answer : "Cere'nin kullanımı sırasında herhangi bir sorunla karşılaşırsanız, sık sorulan sorular bölümünü ziyaret ederek sorularınıza cevaplar bulabilirsiniz. Soru ve sorunlarınız hala çözüme kavuşmamış olursa iletişim formunu doldurabilir veya sorularınızı info@cerebrumtechnologies.com e-posta adresi üzerinden destek ekibimize iletebilirsiniz."
  },
  {
    question : "Cere nasıl geliştirildi?",
    answer : "Cere openAI'ın geliştirdiği GPT-3 ve GPT-4 modelleri baz alarak ince ayarlar ile yeniden eğitilmiş bir karakterdir. Yeniden eğitim kısmında Cere'nin modüler biçimde güncel verilere erişmesi ve daha cana yakın bir karakter olması hedeflenmiştir."
  },
]
const faqTR_EnterpriseData = [
  {
    question : "Cere'nin kurumsal müşteriler için sunduğu hizmetler nelerdir? ",
    answer : 'Cere, kurumsal müşterilere yapay zeka ve doğal dil işleme teknolojileri ile destek sağlar. İletişim ve verimlilik alanlarında özelleştirilebilir çözümler sunarak iş süreçlerini optimize etmeye ve çalışanların performansını artırmaya yardımcı olur. Cere etkileyici ve kişiselleştirilmiş müşteri deneyimleri yaratmaya imkan tanır.'
  },
  {
    question : "Cere, hangi sektörlere hizmet veriyor? ",
    answer : "Cere, birçok farklı sektöre hizmet verebilecek bir yapay zeka teknolojisi platformudur. Finans, sağlık, perakende, e-ticaret, lojistik, üretim, turizm ve daha birçok sektörde kullanılabilir. Cere, özelleştirilebilir bir platform olduğundan, sektörün ihtiyaçlarına göre özelleştirilebilir ve kullanılabilir."
  },
  {
    question : "Cere'nin veri güvenliği politikaları nelerdir? ",
    answer : 'Cere, müşteri verilerinin güvenliğini sağlamak için çeşitli önlemler almaktadır. Bunlar arasında verilerin şifrelenmesi, sıkı kimlik doğrulama prosedürleri ve veri erişim izinlerinin kontrolü bulunmaktadır. Daha fazla bilgi için kullanıcı sözleşmesini ve aydınlatma metnini okuyabilirsiniz.'
  },
  {
    question : 'Cere, kurumsal müşterilerine nasıl bir fiyatlandırma yapıyor? ',
    answer : 'Müşterinin ihtiyaçlarına göre değişen bir fiyatlandırma modeli sunar. Müşteriler, belirli bir hizmet paketi veya özellik seti için ödeme yapabilirler veya özelleştirilmiş bir fiyatlandırma seçeneği için "Bize Ulaşın" alanından iletişime geçebilirler.'
  },  
]

export function Faq() {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Container className={classes.wrapper}>
      <Title align="center" className={classes.title}>
        {t("pages.faq.text1")}
      </Title>

      <Accordion variant="separated" multiple={false}>
        {
          i18n.resolvedLanguage === 'en' ?
            faqENData.map((item,index)=>{
              return (
                <Accordion.Item key={index+1000} className={classes.item} value={`${index}`}>
                  <Accordion.Control sx={{backgroundColor:'a64dff'}}>{item.question}</Accordion.Control>
                  <Accordion.Panel sx={{backgroundColor:'a64dff'}}>{item.answer}</Accordion.Panel>
                </Accordion.Item>
              )
            })
            :
            faqTRData.map((item,index)=>{
              return (
                <Accordion.Item key={index+1000} className={classes.item} value={`${index}`}>
                  <Accordion.Control sx={{backgroundColor:'a64dff'}}>{item.question}</Accordion.Control>
                  <Accordion.Panel sx={{backgroundColor:'a64dff'}}>{item.answer}</Accordion.Panel>
                </Accordion.Item>
              )
            })
        }
      </Accordion>


      <Title align="center" order={3} className={classes.title}>
        {t("pages.faq.text2")}
      </Title>

      <Accordion variant="separated" multiple={false}>
        {
          i18n.resolvedLanguage === 'en' ?
            faqEN_EnterpriseData.map((item,index)=>{
                return (
                  <Accordion.Item key={index+1000} className={classes.item} value={`${index}`}>
                    <Accordion.Control sx={{backgroundColor:'a64dff'}}>{item.question}</Accordion.Control>
                    <Accordion.Panel sx={{backgroundColor:'a64dff'}}>{item.answer}</Accordion.Panel>
                  </Accordion.Item>
                )
            })
            :
            faqTR_EnterpriseData.map((item,index)=>{
              return (
                <Accordion.Item key={index+1000} className={classes.item} value={`${index}`}>
                  <Accordion.Control sx={{backgroundColor:'a64dff'}}>{item.question}</Accordion.Control>
                  <Accordion.Panel sx={{backgroundColor:'a64dff'}}>{item.answer}</Accordion.Panel>
                </Accordion.Item>
              )
            })
        }
      </Accordion>

    </Container>
  );
}