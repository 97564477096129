import React from 'react'
import { Textarea, createStyles } from '@mantine/core';

interface TextAreaProps {
    placeholder: string,
    label?: string | null,
    errorText?: string | null
    props?: any
}

const useStyles = createStyles((theme) => ({
    root: {
      position: 'relative',
      marginTop: 6,
      marginBottom: 14,
      width: 'auto',
    },
  
    input: {
      height: 'auto',
      marginTop: 6,
      borderRadius: 8,
      width: '20vw',
      fontSize: 16,
      minWidth: '300px',
      '&:focus': {
        boxShadow: "1px 1px 2px 2px white",
        borderStyle:'none'
      },
      '&:hover': {
        boxShadow: "1px 1px 2px 2px white",
        borderStyle:'none'
      },
    },

    placeholder:{
      fontSize: 16,
      marginLeft: 12,
    },
  
    label: {
      fontSize: 20,
      zIndex: 1,
      color:'white'
    },
    
  }));

const TextAreaInput = ({placeholder, label, errorText,...props}: TextAreaProps) => {

  const { classes } = useStyles();

  return (
    <Textarea
      {...props}
      placeholder={ placeholder || "123 Center Ln.Plymouth, MN 55441"}
      label={label}
      autosize
      minRows={3}
      classNames={classes}
    />
  )
}

export default TextAreaInput