import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ISubscriptionCreationRequest, ISubscriptionResponse } from './types';
import { RootState } from '../app/store';
import { formValues3 } from '../pages/New/PaymentPage';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/subscriptions/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authState.access_token
      if( token ){
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  
  endpoints: (builder) => ({
    createSubscription : builder.mutation<ISubscriptionResponse,formValues3>({
      query(data) {
        return {
          url: '',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      }
    }),
    getSubscriptionInfo : builder.mutation<ISubscriptionResponse,any>({
      query() {
        return {
          url: ``,
          method: 'GET',
          credentials: 'include',
        };
      }
    }),
    cancelSubscription : builder.mutation<any,any>({
      query() {
        return {
          url: ``,
          method: 'DELETE',
          credentials: 'include',
        };
      }
    })
  }),
});

export const  {
  useCreateSubscriptionMutation,
  useGetSubscriptionInfoMutation,
  useCancelSubscriptionMutation,
} = paymentApi

