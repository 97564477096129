import React from 'react'
import { Overlay } from '@mantine/core'
import { Faq } from '../../components/New/Faq'

const FAQPage = () => {
  return (
    <div className='flex flex-row items-start justify-start w-[100vw] min-h-[95vh]'>
      <Faq />
    </div>
  )
}

export default FAQPage