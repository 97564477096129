import { createStyles, TextInput } from '@mantine/core'
import React from 'react'

const useStyles = createStyles((theme) => ({
    root: {
      position: 'relative',
      marginTop: 6,
      marginBottom: 14,
      width: 'auto',
    },
  
    input: {
      height: 'auto',
      marginTop: 6,
      borderRadius: 8,
      width: '20vw',
      fontSize: 16,
      minWidth: '300px',
      '&:focus': {
        boxShadow: "1px 1px 2px 2px white",
        borderStyle:'none'
      },
      '&:hover': {
        boxShadow: "1px 1px 2px 2px white",
        borderStyle:'none'
      },
    },

    // placeholder:{
    //   marginLeft: 12,
    // },
  
    label: {
      fontSize: 20,
      zIndex: 1,
      color:'white',
    },
  }));

const PaymentTextInput = ({label, placeholder, ...props}:{label:string, placeholder:string, props?:any}) => {

  const { classes } = useStyles();
  
  return (
    <TextInput  label={label} placeholder={placeholder} classNames={classes} {...props} />
  )
}

export default PaymentTextInput