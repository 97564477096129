import React from 'react'
import { useTranslation } from 'react-i18next';
import Cere1 from '../../../assets/cere1.png'
import HeroTextSection from './HeroTextSection';

const NewHeroSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="text-gray-600 body-font z-50 h-auto min-h-screen snap-center relative mt-4 max-w-7xl">
        <div className="container mx-auto flex md:justify-between px-5 md:flex-row flex-col items-center pt-4">
          <HeroTextSection />
          <div className="w-auto  max-w-[450px] justify-items-end">
            <img className="object-cover object-center rounded  max-h-[600px] self-end" alt={'hero'}  src={Cere1}/>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewHeroSection