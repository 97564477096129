import React from 'react'
// import Header from './Header'

const RedirectCard = () => {
  return (
    
      <div className='p-6 rounded-lg flex flex-col items-center justify-center  max-w-sm'>
        <div className="flex flex-col h-auto w-96  justify-self-start" >
          <p className='text-center text-lg text-white'>
            Hesabınız onaylandı.  
          </p>
          <p className='text-center text-lg text-white'>
            Giriş sayfasına yönlendiriliyorsunuz...
          </p>
        </div>
      </div>
    
  )
}

export default RedirectCard