import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const uploadAPI = createApi({
  reducerPath: 'uploadAPI',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.ceremeet.com/api/' }),
  endpoints: (builder) => ({
    uploadPresentation: builder.mutation<{}, FormData>({
      query(data) {
        return {
          url: 'meetings/ebc1e445-6778-433b-b874-bb8618d7fe49',
          method: 'PATCH',
          credentials: 'include',
          body: data,
        };
      },
    }),
    uploadSinglePresentation: builder.mutation<{}, FormData>({
      query(data) {
        return {
          url: 'meetings/ebc1e445-6778-433b-b874-bb8618d7fe49',
          method: 'PATCH',
          credentials: 'include',
          body: data,
        };
      },
    }),
    uploadMultiplePresentation: builder.mutation<{}, FormData>({
      query(data) {
        return {
          url: '',
          method: 'PATCH',
          credentials: 'include',
          body: data,
        };
      },
    }),
  }),
});

export const { useUploadPresentationMutation } = uploadAPI;
