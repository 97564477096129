import React from 'react'
import subs_icon from '../../assets/subs_icon.png'
import { useTranslation } from 'react-i18next';
const BasketItem = ({product_name, price}:{product_name:string,price:string}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className='flex flex-row w-full h-24 bg-transparent justify-between rounded-md  mb-4 bg-[#009999] hover:scale-105 shadow-md transition'>
      <div className='flex flex-row flex-1 justify-center items-center border-r-[1px] border-white px-[2px]'>
        {/* <img src={subs_icon} className='w-12 h-12 mr-4 justify-self-start' alt='Date Icon'/> */}
        <span className='font-semibold text-base text-white'>{product_name} {t("pages.payment.form1.text3")}</span>
        {/* <span className='font-normal text-xs text-slate-800'> 1 Adet</span> */}
      </div>
      <div className='flex flex-row justify-center items-center w-20'>
        {/* <span className='font-medium text-lg text-slate-800'>{product_name}</span> */}
        <span className='text-white text-base font-semibold'>
          {price}
        </span>
      </div>
    </div>
  )
}

export default BasketItem

// dispatch(setSelectedSubType(pricesList.map((item:IPriceItem)=> {
//   if(item.currencyCode === 'USD' && item.pricingPlanReferenceCode === 'b88b27d1-f383-476f-9488-ed6d83f018f2')
//   return item
// })))