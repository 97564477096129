import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

interface IUserAuth {
  isLoggedIn?: boolean
  status: string | null;
  access_token: string | null;
  refresh_token: string | null;
}

const initialState: IUserAuth = {
  isLoggedIn: false,
  status: null,
  access_token: null,
  refresh_token: null
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    setAuthCredentials: (state, action: PayloadAction<IUserAuth>) => {
      state.isLoggedIn = true;
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.status = action.payload.status;
    },
    deleteToken: (state) => {
      state.isLoggedIn = false;
      state.access_token = null;
      state.status = null;
      state.refresh_token = null;
    }
  },
});

export const getToken = (state:RootState) => state.authState.access_token

export default authSlice.reducer;

export const { setAuthCredentials, deleteToken } = authSlice.actions;